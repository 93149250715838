.form-control {
  padding: 5px 65px 5px 10px;

  font-family: "Rawline";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  color: #000000;

  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #000000;
  border-radius: 25px;
}
input::-webkit-input-placeholder {
  color: #bebebe;
}

input:-moz-placeholder {
  color: #bebebe;
}

textarea::-webkit-input-placeholder {
  color: #bebebe;
}

textarea:-moz-placeholder {
  color: #bebebe;
}
