.accordion-button {
  padding: 0;
  width: auto;
  background: transparent;
  &:not(.collapsed) {
    color: transparent;
    background-color: transparent;

    box-shadow: none;
    &::after {
      background-image: url("./img/sprite.svg#ic-arrow");
      --bs-accordion-btn-icon-width: 15px;
    }
  }
  &::after {
    background-image: url("./img/sprite.svg#ic-arrow");
    --bs-accordion-btn-icon-width: 15px;
  }
  &:focus {
    border-color: none;
    box-shadow: none;
  }
}

.accordion-item {
  color: var(--color-black);
  background-color: transparent;
  border-top: 1px solid var(--color-black);
  border-bottom: 1px solid var(--color-black);
  border-left: 0;
  border-right: 0;

  &:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:last-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:not(:first-of-type) {
    border-top: 1px solid var(--color-black);
  }
}
.accordion-item,
.photo {
  transition: all 0.3s linear 0.2s;
}
.accordion {
  width: 100%;
  .accordion-item {
    &.play {
      .audio-number {
        font-size: 0 !important;
      }
      .audio-play {
        background-image: url("./img/sprite.svg#ic-pause") !important;
        visibility: visible !important;
        opacity: 1 !important;
        &:hover {
          opacity: 0.3;
        }
      }
    }
    &.audio {
      .timeDuration {
        display: block;
      }
      .timeCurrent {
        display: none;
      }
      &.play {
        background-color: #e5e5e5;
        .timeCurrent {
          display: block;
        }
        .timeDuration {
          display: none;
        }
      }
      &.active {
        background-color: #e5e5e5;
      }
    }
  }

  &:hover {
    .accordion-item {
      .audio-playPause {
        .audio-number {
          font-size: 0;
        }
        .audio-play {
          visibility: visible;
          opacity: 1;
          &:hover {
            opacity: 0.3;
          }
        }
      }
      // box-shadow: 0px -1px 0px 0px rgba(34, 60, 80, 1);
      // box-shadow: 0px 1px 0px 0px rgba(34, 60, 80, 1);
      &:not(:hover) {
        color: #bebebe;
        border-top: 1px solid #bebebe;
        border-bottom: 1px solid #bebebe;
        .audio-playPause {
          .audio-number {
            font-size: inherit;
          }
          .audio-play {
            visibility: hidden;
            opacity: 0;
          }
        }
        svg {
          fill: #bebebe;
          // stroke: #bebebe;
        }
        .audio-webform {
          opacity: 0.2;
        }

        .photo {
          opacity: 0.3;
        }
      }
    }
  }
}
.audio-header {
  display: flex;

  max-width: 100%;
  width: 100%;
  row-gap: 22px;
  @include resp(
    flex-wrap,
    (
      md-down: wrap,
    )
  );
}
.audio-number {
  display: flex;
  transition: all 0.3s linear 0.1s;
}
.audio-photo-albom {
  min-width: 60px;
}
.audio-playPause {
  min-width: 39px;
  cursor: pointer;
}
.audio-play {
  visibility: hidden;
  opacity: 0;
  margin-top: 4px;
  background-image: url("./img/sprite.svg#ic-play");
}
.audio-play {
  width: 12px;
  height: 12px;

  background-repeat: no-repeat;
  background-size: 12px 12px;
  transition: all 0.3s linear 0.1s;
}
.audio-next,
.audio-prev {
  background-image: url("./img/sprite.svg#ic-next-play");

  @include resp(
    width,
    (
      md-down: 15px,
      lg: 17px,
    ),
    height
  );
  @include resp(
    background-size,
    (
      md-down: 15px 15px,
      lg: 17px 17px,
    )
  );
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.3s linear 0.1s;
  &:hover {
    opacity: 0.3;
  }
  &:active {
    opacity: 1 !important;
  }
}
.audio-prev {
  transform: rotate(180deg);
}
.audio-name {
  text-transform: uppercase;
  font-weight: 600;
  @include resp(
    min-width,
    (
      md-down: auto,
      lg: 250px,
    )
  );

  @include resp(
    width,
    (
      md-down: calc(100% - 40px - 145px),
    )
  );
}
.container-treks {
  .audio-name {
    @include resp(
      width,
      (
        md-down: calc(100% - 40px - 215px),
      )
    );
  }
}
.audio-time {
  @include resp(
    min-width,
    (
      md-down: 110px,
      lg: 150px,
    )
  );
}
.audio-webform {
  transition: all 0.3s linear 0.1s;
  cursor: pointer;

  div {
    width: 100%;
  }
  @include resp(
    margin-right,
    (
      md-down: 0,
      lg: 58px,
    )
  );

  @include resp(
    width,
    (
      md-down: calc(100% - 120px),
      lg: 100%,
    )
  );
  .photo {
    height: 20px;
    padding-bottom: 0;
  }
}
.audio-btn {
  svg {
    width: 15px;
    height: 15px;
    transition: all 0.3s linear 0.1s;
  }
  .add_to_cart_btn {
    .ic-plus {
      transition: all 0.3s linear 0.1s;
    }
    .ic-basketAudio.d-none {
      transition: all 0.3s linear 0.1s;
    }
    &:active {
      .ic-plus {
        display: none;
      }
      .ic-basketAudio.d-none {
        display: block !important;
      }
    }
  }
  // .audioBasket {
  //   .ic-plus {
  //     display: none;
  //   }
  // }
}

.audio--playActive {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: #e5e5e5;
  font-size: 14px;
  .audio-play {
    visibility: visible;
    opacity: 1;
  }
  .audio-playPause {
    min-width: 100px;
    display: flex;
    gap: 24px;
    .audio-play {
      width: 18px;
      height: 19px;

      background-size: 18px 19px;
      &:hover {
        opacity: 0.3;
      }
      &:active {
        opacity: 1 !important;
      }
    }
    .audio-play.play {
      background-image: url("./img/sprite.svg#ic-pause");
    }
  }
  .audio-time {
    min-width: 36.5px;
  }
  .audio-webform {
    height: 30px;
    @include resp(
      width,
      (
        md-down: calc(100% - 60px),
      )
    );
    div {
      height: 30px;
    }
  }
  .audio-name {
    @include resp(
      width,
      (
        lg-down: calc(100% - 500px),
        md-down: calc(100% - 100px),
      )
    );
  }
  .accordion-header {
    @include resp(
      flex-wrap,
      (
        lg-down: wrap,
      )
    );
    @include resp(
      row-gap,
      (
        lg-down: 20px,
      )
    );
  }
  .audio-playPause,
  .audio-btn {
    @include resp(
      min-width,
      (
        md-down: 50%,
      )
    );
  }
  .audio-btn {
    @include resp(
      justify-content,
      (
        md-down: flex-end,
      )
    );
    @include resp(
      position,
      (
        md-down: static,
      )
    );
    .close-button {
      @include resp(
        position,
        (
          md-down: absolute,
        )
      );
      @include resp(
        top,
        (
          md-down: 10px,
        )
      );
    }
  }
}
.property-list {
  &__item-alboms {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .property-info {
    font-weight: 600;
  }
}
.accordion-body {
  padding: 0;
  &__content {
    max-width: 800px;
  }
  &__property-list {
    display: flex;
    column-gap: 30px;
    row-gap: 5px;
    @include resp(
      flex-direction,
      (
        md-down: column,
      )
    );

    li {
      display: flex;
      @include resp(
        gap,
        (
          md-down: 14px,
          lg: 8px,
        )
      );
    }
  }
}

#treks {
  .audio-name {
    @include resp(
      min-width,
      (
        lg: 280px,
        xl: 520px,
      )
    );
  }
}
