/* stylelint-disable */

//xxl
@mixin big-desktop {
  @media (max-width: (1600px)) {
    @content;
  }
}
//xll
@mixin min-desktop {
  @media (max-width: (1400px)) {
    @content;
  }
}
//xl
@mixin tablet {
  @media (max-width: (1024px)) {
    @content;
  }
}
//lg
@mixin small-tablet {
  @media (max-width: (768px)) {
    @content;
  }
}
//md
@mixin mobile {
  @media (max-width: (576px)) {
    @content;
  }
}

//sm 0-576
