// базовые подключения
/*! Theme Name: Insait
Theme URI:
Author: Kalinkidev
Author URI:
Description: Original theme
Version: 1.0*/
@import "vars";

@import "normalize";
@import "mixins";
@import "fonts";
@import "settings";
@import "./components/btn";
@import "./components/forms";
@import "./components/langes";
@import "./components/accordion";
@import "./components/modal-alboms";
@import "./components/Filter/Filter";
// подключения компонентов страницы
@import "./components/header";

.section {
  &__news-albom {
    .col {
      min-width: 340px;
      &:nth-child(3) {
        @include resp(
          display,
          (
            lg-down: none,
            sm-down: block,
          )
        );
      }
    }
  }
  &__popular-albom {
    .col {
      min-width: 170px;
      &:nth-last-child(-n + 4) {
        @include resp(
          display,
          (
            lg-down: none,
            sm-down: block,
          )
        );
      }
    }
  }
  &__playilist {
    .col {
      min-width: 227px;
      &:nth-child(4) {
        @include resp(
          display,
          (
            lg-down: none,
          )
        );
      }
    }
  }
  &__films {
    .col {
      min-width: 227px;
      &:nth-child(5) {
        @include resp(
          display,
          (
            lg-down: none,
          )
        );
      }
    }
    .row-scroll {
      @include resp(
        flex-wrap,
        (
          1080px: nowrap,
          1200px: wrap,
        )
      );
    }
  }
  .row-scroll {
    @include resp(
      padding,
      (
        lg-down: 0 30px,
      )
    );
    @include resp(
      margin-left,
      (
        lg-down: -30px,
      )
    );
    @include resp(
      margin-right,
      (
        lg-down: -30px,
      )
    );
    @include resp(
      flex-wrap,
      (
        md-down: nowrap,
      )
    );
    @include resp(
      overflow-x,
      (
        md-down: scroll,
      )
    );
    &:-webkit-scrollbar {
      display: none;
      height: 0;
      width: 0;
      background: transparent;
    }
    .photo,
    .card-info {
      transition: all 0.3s linear 0.4s;
    }
    &:hover {
      .col {
        &:not(:hover) {
          color: #bebebe;
          .photo {
            opacity: 0.3;
          }
        }
      }
    }
  }
}

.card-block {
  cursor: pointer;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.card-info {
  padding-right: 30px;
  @include resp(
    margin-bottom,
    (
      sm-down: 23px,
      md: 25px,
    )
  );

  text-transform: uppercase;
}
.card-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 9px;
}
.card-date {
  font-size: 12px;
  font-weight: 400;
}
.card-null {
  font-size: 20px;
  font-weight: 600;

  text-transform: uppercase;
}
@import "./components/footer";
@import "./components/wp-paginavi";
.input-form-block,
.wpc-filters-section.wpc-filter-layout-search-field {
  width: fit-content;
  border: 1px solid var(--color-black);
  border-radius: 25px;

  display: flex;
  gap: 30px;
  font-size: 14px;
  line-height: 1.3;
  text-transform: uppercase;
  @include resp(
    width,
    (
      md-down: 100%,
    )
  );
  @include resp(
    padding,
    (
      md-down: 8px 20px,
      lg: 6px 15px,
    )
  );
  input {
    border: 0 !important;
    box-shadow: none !important;
    outline: 0 !important;
    width: 100%;
    @include resp(
      width,
      (
        md-down: 100%,
      )
    );
    &::placeholder {
      color: var(--color-grey);
      font-weight: 400;
    }

    input:focus-visible {
      border: 0 !important;
      box-shadow: none !important;
      outline: 0 !important;
    }
  }
}
.wpcf7 {
  .input-form-block {
  }
  .wpcf7-submit {
    opacity: 0;
    visibility: hidden;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: -3px;
    width: fit-content;
    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}
.wpcf7-spinner {
  display: none !important;
}

.error404 {
  a.main__link {
    text-underline-offset: 4px;
  }
  .footer {
    .footer__sosial {
      li {
        &:first-child {
          @include resp(
            margin-right,
            (
              sm-down: auto,
            )
          );
        }
      }
    }
  }
}

.modal-basket {
  .modal-dialog {
    margin: 0;
  }
  .modal-content {
    height: 100%;
    min-height: 100vh;
  }
  .woocommerce-cart-form {
    .remove {
      width: 15px;
      height: 15px;
      background-image: url("./img/sprite.svg#ic-close");
      background-repeat: no-repeat;
      background-size: 15px 15px;
    }
  }
}
.woocommerce-notices-wrapper,
.woocommerce-result-count {
  display: none;
}
.count {
  color: var(--color-black);
}
#myTab {
  button {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    text-transform: uppercase;
    &.active,
    &:hover {
      color: var(--color-grey);
    }
  }
}
