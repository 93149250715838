.modal-body {
  padding: 0;
  font-size: 14px;
}
.modal-fullscreen {
  .btn-close {
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 2;
  }
  .descr-albom {
    max-width: 660px;
    width: 100%;
  }
  .block-ic-button {
    display: flex;
    @include resp(
      gap,
      (
        md-down: 20px,
        lg: 36px,
      )
    );
  }
  .button {
    width: 230px;
  }
  .block-ic-albom {
    display: flex;

    @include resp(
      gap,
      (
        md-down: 10px,
        lg: 15px,
      )
    );
  }
}

.modal-header {
  &__albom {
    z-index: 1;
    @include resp(
      position,
      (
        xl: fixed,
      )
    );
    @include resp(
      height,
      (
        lg: 248px,
        xl: 100%,
      )
    );
    height: 100%;
    display: flex;
    justify-content: space-between;

    @include resp(
      flex-direction,
      (
        xl: column,
      )
    );
  }
  &__albom-photo {
    @include resp(
      padding-bottom,
      (
        md-down: 100%,
        lg: 0,
        xl: 100%,
      )
    );
    flex-shrink: 0;
    height: 0;
    @include resp(
      height,
      (
        md-down: 0,
        lg: 100%,
        xl: 0,
      )
    );
    @include resp(
      width,
      (
        md-down: 100%,
        lg: 248px,
        xl: 100%,
      )
    );
  }
  &__modal-info {
    width: 100%;
    @include resp(
      height,
      (
        xl: 100%,
      )
    );

    .modal-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      @include resp(
        gap,
        (
          md-down: 20px,
          lg: 50px,
        )
      );
      @include resp(
        padding,
        (
          md-down: 0,
          lg: 30px,
        )
      );
      font-size: 14px;
      .property-list {
        display: flex;
        flex-direction: column;
        @include resp(
          gap,
          (
            md-down: 5px,
            lg: 2px,
          )
        );
        li {
          display: flex;
          gap: 14px;
        }
      }
    }
  }
}

.modal-content {
  &__albom {
    @include resp(
      position,
      (
        xl: absolute,
      )
    );
    @include resp(
      right,
      (
        xl: 0,
      )
    );
  }
  .count-trackes {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.2;
  }
}
