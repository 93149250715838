.block-button {
  @include flex;
  gap: 15px;
}
.button {
  @include flex;
  @include flex-all-center;
  border-radius: 25px;
  width: 100%;

  @include resp(
    padding,
    (
      sm-down: 7px 10px 9px 10px,
      md: 4px 10px 6px 10px,
    )
  );
  font-size: 14px;
  font-weight: 400;
  background-color: var(--color-black);
  color: var(--color-white);
  line-height: 1.3;
  text-transform: uppercase;
  gap: 12px;
  transition: all 0.3s linear 0.1s;
}
.button-play:after {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  background-size: 10px 10px;
  background-image: url("../img/sprite.svg#ic-play");
  background-repeat: no-repeat;
}
.button-pause:after {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  background-size: 10px 10px;
  background-image: url("../img/sprite.svg#ic-pause");
  background-repeat: no-repeat;
}
.button:hover {
  background: var(--color-grey);
  color: var(--color-white);
}
.button:active {
  background-color: var(--color-black);
  transition: none;
  color: var(--color-white);
}
