*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family);
  -webkit-text-size-adjust: 100%;
}

.page body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 1.3;
  font-size: 14px;
  overflow-x: hidden;

  background-color: var(--color-white);
  color: var(--color-black);
}

img {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
}

a,
input {
  color: #000000;
  text-decoration: none;
}

a,
button,
*:before,
*:after,
.time,
a:hover {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

* {
  position: relative;
  margin: 0px;
  padding: 0px;
  list-style: none;
  vertical-align: top;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none !important;
  background: none;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -khtml-border-radius: 0px;
  border-radius: 0px;
  line-height: 1.3;
  border: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

p,
p * {
  line-height: 1.3;
}
.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  // position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.section {
  &--mt {
    @include resp(
      margin-top,
      (
        sm-down: 120px,
        md: 240px,
      )
    );
  }
  &--mb {
    @include resp(
      margin-bottom,
      (
        sm-down: 120px,
        md: 230px,
      )
    );
  }
  &--pb {
    @include resp(
      padding-bottom,
      (
        sm-down: 40px,
        md: 70px,
      )
    );
  }
  &--null-mt {
    margin-top: 0;
  }
  &--null-mb {
    margin-bottom: 0;
  }
  &__block-title {
    color: #000000;

    text-transform: uppercase;

    @include resp(
      margin-bottom,
      (
        sm-down: 50px,
        md: 104px,
      )
    );
    align-items: center;
  }

  // .section__subtitle

  &__subtitle {
  }

  // .section__title

  &__title {
    @include resp(
      font-size,
      (
        sm-down: 27px,
        md: 35px,
      )
    );

    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.7px;
  }
  &__button {
    display: flex;
    justify-content: flex-end;
  }
}

.logo {
  @include resp(
    width,
    (
      sm-down: 60px,
      md: 150px,
    )
  );
  @include resp(
    height,
    (
      sm-down: 40px,
      md: 30px,
    )
  );
  flex-shrink: 0;
  display: block;
}
.nav {
  ol,
  ul {
    margin: 0;
    padding: 0;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  a {
    font-weight: 500;
    font-size: 12px;
    color: var(--color-black);
    line-height: 1.3;
    text-transform: uppercase;
  }
  &.not-home {
    a {
      color: var(--color-grey);
    }
    a:hover {
      color: var(--color-black);
    }
    a:active {
      color: var(--color-grey);
    }
    .current_page_item {
      a {
        color: var(--color-black);
        pointer-events: none;
      }
    }
  }
}
a:hover {
  color: var(--color-grey);
}
a:active {
  color: var(--color-black);
}
.icon-link {
  width: 28px;
  height: 28px;
  display: flex;
  svg {
    width: 100%;
    height: 100%;
  }
}

.icon-link:hover {
  fill: var(--color-grey);
}
.icon-link:active {
  fill: var(--color-black);
}

.photo {
  width: 100%;
  padding-bottom: 100%;

  img {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.curlce {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid var(--color-black);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s linear 0.1s;
  & .ic {
    width: 10px;
    height: 10px;
  }
  &:hover {
    border: 1px solid var(--color-grey);
    .ic {
      fill: var(--color-grey);
    }
  }
  &:active {
    border: 1px solid var(--color-black);
    transition: all 0.3s linear 0.1s;
    .ic {
      fill: var(--color-black);
    }
  }
}
.curlce-favorite .simplefavorite-button {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid var(--color-black);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s linear 0.1s;
  & .ic {
    width: 10px;
    height: 10px;
  }
  &:hover {
    border: 1px solid var(--color-grey);
    .ic {
      fill: var(--color-grey);
    }
  }
  &:active {
    border: 1px solid var(--color-black);
    transition: all 0.3s linear 0.1s;
    .ic {
      fill: var(--color-black);
    }
  }
}
.ic {
  fill: var(--color-black);
  width: 15px;
  height: 15px;
  cursor: pointer;
  transition: all 0.3s linear 0.1s;
  &:hover {
    fill: var(--color-grey);
  }
  &:active {
    fill: var(--color-black);
  }
  svg {
    flex-shrink: 0;
  }
}
.ic-favorite {
  &:active {
    fill: var(--color-red) !important;
    // stroke: var(--color-red) !important;
  }
}
.simplefavorite-button {
  display: flex;
  width: 15px;
  height: 15px;
  &.active .ic-favorite {
    fill: var(--color-red) !important;
    // stroke: var(--color-red) !important;
  }
}
.curlce-favorite {
  .simplefavorite-button {
    &:active,
    &.active {
      background-color: var(--color-red) !important;
      border-color: var(--color-red) !important;
      .ic-favorite {
        fill: var(--color-white) !important;
        // stroke: var(--color-white) !important;
      }
    }
  }
}
.button-playlist {
  @include resp(
    width,
    (
      md-down: 100% !important,
    )
  );
  .ic {
    fill: var(--color-white) !important;
    // stroke: var(--color-white) !important;
    width: 10px;
    height: 10px;
  }
}
