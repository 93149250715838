.wp-pagenavi,
.woocommerce-pagination .wc-pagination {
  display: flex;
  font-size: 14px;
  font-style: normal;
  justify-content: center;
  @include resp(
    gap,
    (
      sm-down: 29px,
      md: 19px,
    )
  );
  @include resp(
    margin-top,
    (
      sm-down: 55px,
      md: 115px,
    )
  );
  .page,
  .last {
    color: #bebebe;
    &:hover {
      color: #000;
    }
    &:active {
      color: #bebebe;
    }
  }
  .current {
    font-weight: 600;
    color: #000;
  }

  .previouspostslink,
  .nextpostslink,
  .prev,
  .next {
    font-weight: 500;
    position: absolute;
    text-transform: uppercase;
    &:hover {
      color: #bebebe;
    }
    &:active {
      color: #000;
    }
    @include resp(
      font-size,
      (
        sm-down: 0,
      )
    );
    &::before {
      display: block;
      @include resp(
        content,
        (
          sm-down: "",
        )
      );

      width: 15px;
      height: 15px;
      margin-top: 2px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='12' viewBox='0 0 7 12' fill='none'%3E%3Cpath d='M7 6L1 0L0 0.999999L5 6L0 11L1 12L7 6Z' fill='black'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 7px 12px;
      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='12' viewBox='0 0 7 12' fill='none'%3E%3Cpath d='M7 6L1 0L0 0.999999L5 6L0 11L1 12L7 6Z' fill='%23bebebe'/%3E%3C/svg%3E");
      }
      &:active {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='12' viewBox='0 0 7 12' fill='none'%3E%3Cpath d='M7 6L1 0L0 0.999999L5 6L0 11L1 12L7 6Z' fill='black'/%3E%3C/svg%3E");
      }
    }
    // @include resp(ba);
  }
  .previouspostslink,
  .prev {
    left: 0;
    &::before {
      transform: rotate(-180deg);
    }
  }
  .nextpostslink,
  .next {
    right: 0;
  }
}
