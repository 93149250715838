.filter-btn {
  font-size: 14px;

  font-weight: 600;
  line-height: 130%;
  text-transform: uppercase;
  display: flex;

  align-items: center;
  gap: 5px;

  cursor: pointer;
  &::after {
    content: "";
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='7' viewBox='0 0 11 7' fill='none'%3E%3Cpath d='M11 1.49454L10.0065 0.5L5.5 5.01093L0.993549 0.5L0 1.49454L5.5 7L11 1.49454Z' fill='black'/%3E%3C/svg%3E");
    width: 11px;
    height: 7px;
    transition: all 0.3s linear;
    background-size: 11px 7px;
    margin-top: 3px;
  }
  &.active {
    &::after {
      transform: rotate(180deg);
    }
  }
}
.filters-container {
  .widget.widget_wpc_filters_widget {
    overflow: hidden;
    transition: all 0.1s linear;

    background: #e5e5e5;
    margin-top: 27px;
    width: 100%;

    height: 0;
    &.active {
      overflow: visible;

      height: 100%;
    }
  }

  .wpc-filters-main-wrap {
    transition: all 0.3s linear;
    height: fit-content;
    padding: 30px;
  }
}
.filters-container .widget.widget_wpc_filters_widget,
.filters-container .wpc-filters-main-wrap,
.wpc-filters-widget-content.wpc-show-counts-no,
.wpc-filters-widget-containers-wrapper,
.wpc-filters-scroll-container,
.wpc-filters-widget-wrapper {
  position: static !important;
}
// @import search.scss
.wpc-filters-section.wpc-filter-layout-search-field {
  position: absolute !important;
  top: 0px;
  left: 50%;
  transform: translateY(-160px);
  margin: 0 !important;
}
.wpc-filters-section.wpc-filter-layout-search-field,
.wpc-filter-search-form {
  width: 49% !important ;
}
.wpc-filters-widget-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 321px;
}
html .wpc-filters-section {
  margin-bottom: 23px;
}
.wpc-filter-layout-range {
  width: 430px;
}
// filter title
html .wpc-filter-title {
  margin-bottom: 22px;
  font-size: 12px;
  line-height: 120%;
  font-weight: 600;
}
// range
.wpc-filters-range-inputs {
  margin-bottom: 56px;
}
html .wpc-filters-range-inputs .ui-slider-horizontal .ui-slider-range,
html .wpc-filters-range-inputs .ui-slider-horizontal {
  background: #8e8e8e;
}
html .wpc-filters-range-inputs .ui-slider-horizontal .ui-slider-range {
  background-color: #000;
  height: 2px;
}
html .wpc-filters-range-inputs .ui-slider-horizontal {
  height: 1px;
}
html .wpc-filters-range-inputs .ui-slider .ui-slider-handle {
  width: 5px;
  height: 5px;
  background-color: #000;
  box-shadow: none !important;
}
html .wpc-filters-range-inputs .ui-slider-horizontal .ui-slider-handle {
  top: -2.5px;
  margin-left: 0px;
  border-radius: 0;
  outline: 0;
  box-shadow: none !important;
}

html .wpc-filters-range-inputs .ui-button,
html .wpc-filters-range-inputs .ui-state-default,
html .wpc-filters-range-inputs .ui-widget-content .ui-state-default,
html .wpc-filters-range-inputs .ui-widget-header .ui-state-default,
html .wpc-filters-range-inputs .ui-button.ui-state-disabled:active,
html .wpc-filters-range-inputs .ui-button.ui-state-disabled:hover {
  border: 0;
}
.wpc-filters-range-wrapper input[type="number"] {
  font-size: 12px;
}
.wpc-filters-range-max {
  text-align: right;
}

// radio and checkbox
.wpc-filter-layout-checkboxes {
  max-width: 50%;
}
html .wpc-filters-main-wrap .wpc-term-item-content-wrapper {
  width: fit-content;
}
html .wpc-filters-main-wrap input[type="checkbox"],
html .wpc-filters-main-wrap input[type="radio"] {
  width: 9px;
  height: 9px;
  border: 0.8px solid #000;
  background: transparent;
  border-radius: 0px;
  min-width: 9px;
  margin: 2px 0 0 0 !important;

  cursor: pointer;
}

html .wpc-filters-main-wrap input[type="radio"]:hover,
html .wpc-filters-main-wrap input[type="checkbox"]:hover {
  border-color: #8e8e8e;
}
html .wpc-term-item-content-wrapper:hover input {
  border-color: #8e8e8e;
}
html .wpc-term-item-content-wrapper:hover label a {
  color: #8e8e8e;
}
html .wpc-term-item-content-wrapper:active input,
html .wpc-filters-main-wrap input[type="radio"]:checked,
html .wpc-filters-main-wrap input[type="checkbox"]:checked {
  border-color: #000;
  background-color: #000 !important;
}
html .wpc-term-item-content-wrapper:active input ~ label a,
html .wpc-filters-main-wrap input[type="radio"]:checked ~ label a,
html .wpc-filters-main-wrap input[type="checkbox"]:checked ~ label a,
html .wpc-term-item-content-wrapper:active label a {
  color: #000;
}
html .wpc-filters-main-wrap input[type="radio"]:checked:after,
html .wpc-filters-main-wrap input[type="checkbox"]:checked:after {
  display: none;
}
html body .wpc-filters-main-wrap li.wpc-term-item {
  margin-bottom: 0px;
  width: 96px;
}
html .wpc-filters-main-wrap li.wpc-term-item label {
  line-height: 1.2;
  font-size: 12px;
  padding-left: 7px;
}
html .wpc-filters-section:not(.wpc-filter-more-less):not(.wpc-filter-post_meta_num):not(.wpc-filter-tax_numeric):not(.wpc-filter-layout-dropdown) .wpc-filter-content:not(.wpc-filter-has-hierarchy) ul.wpc-filters-ul-list {
  padding: 0;
  max-height: fit-content;
  overflow-y: visible;
  display: flex;
  flex-wrap: wrap;
  row-gap: 7px;
  column-gap: 16px;
}
