.lang-block {
  display: flex;
  // flex-direction: row-reverse;
}
.lang-list {
  display: flex;
  gap: 7px;
  flex-direction: column;
  align-items: baseline;
}

.lang-link {
  color: var(--color-grey);
  &:hover {
    color: var(--color-black);
  }
  &:active {
    color: var(--color-grey);
  }
}
.header {
  .current-lang {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    &::after {
      content: "";
      display: block;
      margin-top: 2px;
      width: 10px;
      height: 7px;
      background-size: 10px 7px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6' fill='none'%3E%3Cpath d='M9.30769 1.34153L8.467 0.5L4.65385 4.31694L0.840695 0.5L0 1.34153L4.65385 6L9.30769 1.34153Z' fill='black'/%3E%3C/svg%3E");
    }
    & ~ .lang-block-items {
      visibility: hidden;

      opacity: 0;
      position: absolute;
      z-index: 2;
      top: 100%;
      left: 0;
      padding-top: 9px;
      transition: all 0.3s linear;
    }
    &.show {
      & ~ .lang-block-items {
        visibility: visible;
        opacity: 1;
      }
      &::after {
        transform: rotate(180deg);
      }
    }
  }
  .row--mobileMenu {
    .lang-list {
      flex-direction: row;
      gap: 18px;
      font-size: 14px;
      font-weight: 600;
      .lang-link {
        color: #bebebe;
      }
    }
    .current-lang {
      &::after {
        display: none;
      }
      & ~ .lang-block-items {
        visibility: visible;
        opacity: 1;
        position: static;
        padding-top: 0px;
        transition: all 0.3s linear;
      }
    }
  }
}
.footer {
  .current-lang {
    color: #000000;
  }
  .lang-link {
    color: #bebebe;
  }
}
