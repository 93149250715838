.footer {
  margin-top: -4px;

  font-size: 12px;

  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  &__subscribe {
    .input-form-block {
      @include resp(
        width,
        (
          sm-down: 100%,
          lg: 90%,
          xl: 85%,
        )
      );
    }
  }
  &__subscribe-text {
    @include resp(
      font-size,
      (
        sm-down: 20px,
        md: 23px,
      )
    );
    font-weight: 600;

    letter-spacing: -0.46px;
    text-transform: uppercase;

    @include resp(
      margin-bottom,
      (
        sm-down: 24px,
        md: 27px,
      )
    );
    margin-top: -4px;
  }
  .form-control {
    max-width: 460px;
  }
  // временно
  .menu {
    display: flex;
    gap: 7px;
    flex-direction: column;
  }
  li {
    line-height: 1.1;
  }
  &__corp {
  }
  &__sosial {
    display: flex;

    @include resp(
      gap,
      (
        md-down: 10px,
        sm-down: 19px,

        lg: 19px,
      )
    );

    @include resp(
      flex-direction,
      (
        md-down: column,
        sm-down: row,
      )
    );
    @include resp(
      align-items,
      (
        md-down: flex-end,
        sm-down: flex-start,
      )
    );
  }

  // .footer__list-design

  &__list-design {
    display: flex;

    @include resp(
      flex-direction,
      (
        sm-down: row,
        md: row-reverse,
      )
    );
  }
}
