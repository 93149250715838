.header {
  @include resp(
    padding,
    (
      sm-down: 25px 0,
      md: 27px 0,
    )
  );

  &__icon-block {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  &__icon-items {
    display: flex;
    gap: 27px;
    font-size: 12px;
    font-weight: 500;
  }
  &__icon-item {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    &:hover {
      .ic {
        fill: var(--color-grey);
        // stroke: var(--color-grey);
      }
    }
    &:active {
      .ic {
        fill: var(--color-black);
        // stroke: var(--color-black);
      }
    }
    .icon-link {
      width: 15px;
      height: 15px;
    }
    .page-favorites {
      pointer-events: none;
      .ic-favorite {
        fill: var(--color-red);
        // stroke: var(--color-red);
      }
    }
  }
  .block-col {
  }
}

.burger {
  @include burger;
}

.row--mobileMenu {
  @include resp(
    left,
    (
      md-down: 10px,
      lg: 0,
    )
  );

  right: 0;
  z-index: 1;
  background-color: #fff;
  height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
  .col-12-w {
    @include resp(
      width,
      (
        lg: 52px,
      )
    );
  }
  .header__block-col--mobileMenu {
    height: calc(100% - 60px);
    justify-content: space-between;
  }
  &.menu--active {
    opacity: 1;
    height: fit-content;
    // @include resp(
    //   height,
    //   (
    //     sm-down: 100vh,
    //     md: fit-content,
    //   )
    // );
    @include resp(
      min-height,
      (
        md-down: 100vh,
      )
    );
    visibility: visible;
    overflow: visible;
  }
  .nav {
    a {
      @include resp(
        font-size,
        (
          md-down: 20px,
        )
      );
      @include resp(
        font-weight,
        (
          md-down: 600,
        )
      );
    }
  }
}
